import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Services from "../components/services"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import Process from "../components/process"

const IndexPage = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Home" />
        <Hero />
        <Services />
        <Process />
      </Layout>
    </ThemeProvider>
    <div id="ff-compose"></div>
  </React.Fragment>
  
)

export default IndexPage
