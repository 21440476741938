import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Bar from "./bar"

const Process = () => {
  return (
    <Grid id="Process"
      container
      spacing={3}
      style={{
        textAlign: "center",
        minHeight: 200,
        padding: 10,
        paddingTop: 50,
      }}
    >
      <Grid
        item
        xs={12}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Typography variant="h5" color="primary">Learn More About Our Process</Typography>
        <Bar />
        <Typography variant="subtitle1" style={{ margin: 30 }}>
          We believe a vast majority of patients in the healthcare industry have been prescribed medications that are ineffective in providing therapy or remedy.  Most medication in the United States is prescribed based on reported symptoms by the patient, and rarely on bloodwork from their provider.  We believe there’s a better way. Here is how we help:
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Typography variant="h5" color="secondary">Discovery</Typography>
        <Bar />
        <Typography variant="subtitle1" style={{ margin: 30 }}>
          We ask that you fill out an intake form in which we gather information demographics, insurance, and medication information. 
          Then during a 60-90 minute appointment we establish a personal relationship and identify treatment areas, health goals, and discuss a functional medicine approach.   
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{
          backgroundColor: "white",
        }}
      >
        <Typography variant="h5" color="secondary">Fact Finding</Typography>
        <Bar />
        <Typography variant="subtitle1" style={{ margin: 30 }}>
          We have a 60 minute appointment to evaluate symptoms and determine appropriate labs to order, giving us a more complete picture of your health.  
          We work with our network of licensed practitioner partners to offer these comprehensive lab services. 
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{
          backgroundColor: "white",
        }}
      >
        <Typography variant="h5" color="secondary">Lab Overview and Referrals</Typography>
        <Bar />
        <Typography variant="subtitle1" style={{ margin: 30 }}>
          Once your lab results have been received, we have another appointment to interpret the results and explain their impacts.  
          We may recommend products and OTC supplements to begin treatment.  If medication is required, we make referrals to the appropriate prescribers. 
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Button variant="contained" color="secondary" size="large" href="./discovery">
          Get Started
        </Button>
      </Grid>
    </Grid>
  )
}
export default Process
