import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import ServiceCard from "./service-card"
import Bar from "./bar"

const Services = () => {
  let services = [
    {
      id: 1,
      image: "search-doctor-icon.png",
      title: "Find Medical Providers",
      description:
        "Identifying practitioners who are affordable and in your insurance network",
    },
    {
      id: 2,
      image: "tracking-icon.png",
      title: "Personal Guidance",
      description:
        "Help organize healthcare data from visits and superbills to explanation of benefits (EOB’s)",
    },
    {
      id: 3,
      image: "consultation-icon.png",
      title: "Personal Assistance",
      description:
        "Assist in scheduling appointments and organizing feedback from your health team",
    },
    {
      id: 4,
      image: "consultation-icon.png",
      title: "Consultation",
      description:
        "Free consultation with our trusted doctors and get the best recommendations.",
    },
    {
      id: 5,
      image: "emergency-care-icon.png",
      title: "Expectation Management",
      description:
        "Set expectations for things you may experience in your assessments with practitioners",
    },
    {
      id: 6,
      image: "details-info-icon.png",
      title: "Diagnostics Explanation",
      description: "Research diagnostic codes provided by other medical professionals, and how you are charged for them",
    },
  ]

  return (
    <Grid id="Services"
      container
      spacing={3}
      style={{
        textAlign: "center",
        minHeight: 200,
        padding: 50,
      }}
    >
      <Grid
        item
        xs={12}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Typography variant="h5" color="primary">How We Can Help</Typography>
        <Bar />
        <Typography variant="subtitle1" style={{ margin: 30 }}>
          When you enroll, we gather information through a series of appointments and make referrals to our network of
          vetted healthcare professionals, creating a team specifically for you and your health related concerns. Some of the
          services we offer include the following:
        </Typography>
      </Grid>

      {services.map(service => (
        <Grid item xs={12} sm={4} key={service.id}>
          <ServiceCard service={service} />
        </Grid>
      ))}

      <Grid item xs={12}>
        <Button variant="contained" color="secondary" size="large" href="./discovery">
          Get Started
        </Button>
      </Grid>
    </Grid>
  )
}
export default Services
